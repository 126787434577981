import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ComunidadModel } from '../../../model/comunidad.model';
import { UserModel } from '../../../model/vecino.model';
import { PiscinaModel } from '../../../models/piscina.mode';
import { UtilsService } from '../../../services/utils.service';
import { GetPiscinas } from '../../home/store/home.actions';
import { FILTRO_FECHA_ITEMS, SET_DATE_PNPUT } from '../booking.constants';

@Component({
  selector: 'app-table-piscina',
  templateUrl: './table-piscina.component.html',
  styleUrls: ['./table-piscina.component.scss']
})
export class TablePiscinaComponent implements OnInit {
  @Select((state) => state.core.piscinas) piscinas$: Observable<any>;
  @Input() users: UserModel[] = [];
  @Input() selectedComunity: ComunidadModel;
  data: PiscinaModel[] = null;
  originalData: PiscinaModel[] = null;
  searchValue = '';
  selectedItem: any;
  filtroFechaItems = FILTRO_FECHA_ITEMS;
  setDateInput = SET_DATE_PNPUT;
  selectedFechaOption = '';

  constructor(private store: Store, private datePipe: DatePipe, private utilService: UtilsService) {}

  ngOnInit() {
    this.piscinas$.subscribe((items) => {
      if (items && items.length > 0) {
        this.data = this.mapPiscinaBooking(items);
        this.originalData = Object.assign(this.data);
      } else {
        this.data = [];
      }
    });
    this.refresh();
  }
  exitDetail(done) {
    if (done) {
      this.refresh();
    }
    this.selectedItem = null;
  }
  selectItemPadel(item) {
    this.selectedItem = item;
    this.selectedItem.comunidad = this.selectedComunity;
  }
  clearFilters() {
    this.resetSearch();
    this.refresh();
    this.selectedFechaOption = '';
  }
  refresh() {
    this.store.dispatch(new GetPiscinas());
  }
  resetSearch(): void {
    this.searchValue = '';
    this.search('');
  }

  search(value: string): void {
    this.searchValue = value;
    setTimeout(() => {
      this.data = this.originalData.filter((item: PiscinaModel) =>
        item.username
          .trim()
          .toLowerCase()
          .includes(this.searchValue.trim().toLowerCase())
      );
    }, 500);
  }

  mapPiscinaBooking(items) {
    const result: PiscinaModel[] = [];
    for (const book of items) {
      const item: PiscinaModel = {
        id: book._id,
        code: book.code,
        username: book.username,
        useraddress: book.useraddress,
        persons: book.persons,
        day: book.day,
        userId: book.userId,
        hour: `${book.hour}h`,
        active: book.active,
        expired: book.expired,
        createdAt: book.createdAt
      };
      const exist = result.findIndex((el) => el.id === item.id);
      if (exist === -1) {
        result.push(item);
      }
    }
    return result;
  }

  onDateFilterChange(event) {
    this.data = this.utilService.onDateFilterChange(event, this.originalData);
    this.clearDatePickerInput();
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    const value = event.value;
    const result = [];
    if (type === 'in') {
      this.setDateInput.in.bool = true;
      this.setDateInput.in.value = value;
    } else if (type === 'fin') {
      this.setDateInput.fin.bool = true;
      this.setDateInput.fin.value = value;
    }
    if (this.setDateInput.in.bool && this.setDateInput.fin.bool) {
      this.originalData.forEach((item) => {
        const fechatime = new Date(item.day).getTime();
        const intime = this.setDateInput.in.value.getTime();
        const fintime = this.setDateInput.fin.value.getTime();
        if (intime < fechatime && fechatime < fintime) {
          result.push(item);
        }
      });
      this.data = result;
    }
  }
  clearDatePickerInput() {
    this.setDateInput = {
      in: {
        bool: false,
        value: new Date()
      },
      fin: {
        bool: false,
        value: new Date()
      }
    };
  }

  formatDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
  }
}
