import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { NzModalService } from 'ng-zorro-antd';
import { Observable } from 'rxjs';
import { ReservaHoursDto } from '../../../common/dtos/reserva-hours.dto';
import { RESERVAS_TYPE_ENUM } from '../../../common/enums/reservas-type.enum';
import { AdminUserModel } from '../../../model/admin-user.model';
import { ComunityModel } from '../../../model/comunity.model';
import { AlertService } from '../../../services/alert/alert.service';
import { UpdateLocalRequest, UpdatePadelRequest, UpdateTenisRequest } from '../../home/store/home.actions';

@Component({
  selector: 'app-reservas-settings',
  templateUrl: './reservas-settings.component.html',
  styleUrls: ['./reservas-settings.component.scss']
})
export class ReservasSettingsComponent implements OnInit {
  @Select((state) => state.core.adminUser) adminUser$: Observable<any>;
  @Select((state) => state.core.comunities) comunities$: Observable<any>;
  @Select((state) => state.core.availableHours) availableHours$: Observable<any>;
  selectedComunity: ComunityModel;
  startHour = {
    local: null,
    padel: null,
    tenis: null
  };
  endHour = {
    local: null,
    padel: null,
    tenis: null
  };
  adminUser: AdminUserModel;
  comunities: ComunityModel[] = [];
  availableHours: ReservaHoursDto[] = [];
  availableHoursLocal: ReservaHoursDto;
  availableHoursPadel: ReservaHoursDto;
  availableHoursTenis: ReservaHoursDto;
  localPayValue = false;
  unitPriceLocalValue = '';
  errorMessage = {
    status: false,
    text: 'Por favor revise los campos y vuelve a intentarlo de nuevo'
  };

  constructor(private store: Store, private modal: NzModalService, private alertService: AlertService) {}

  get isLocalActive() {
    return this.selectedComunity && this.selectedComunity.extras && this.selectedComunity.extras.localActive;
  }

  get isPadelActive() {
    return this.selectedComunity && this.selectedComunity.extras && this.selectedComunity.extras.padelActive;
  }

  get isTenisActive() {
    return this.selectedComunity && this.selectedComunity.extras && this.selectedComunity.extras.tenisActive;
  }

  get isLocalFormValid() {
    return (
      this.selectedComunity &&
      this.startHour.local &&
      this.endHour.local &&
      (!this.localPayValue || (this.localPayValue && this.unitPriceLocalValue !== '' && this.unitPriceLocalValue !== '0'))
    );
  }

  get isPadelFormValid() {
    return this.selectedComunity && this.startHour.padel && this.endHour.padel;
  }

  get isTenisFormValid() {
    return this.selectedComunity && this.startHour.tenis && this.endHour.tenis;
  }

  ngOnInit() {
    this.adminUser$.subscribe((adminUser) => {
      if (adminUser) {
        this.adminUser = adminUser;
      }
    });

    this.comunities$.subscribe((comunities) => {
      if (comunities) {
        this.comunities = comunities;
      }
    });

    this.availableHours$.subscribe((availableHours) => {
      if (availableHours) {
        this.availableHours = availableHours;
        if (this.availableHours && this.availableHours.length) {
          this.availableHoursLocal = this.availableHours.find((item) => item.type === RESERVAS_TYPE_ENUM.LOCAL);
          this.availableHoursPadel = this.availableHours.find((item) => item.type === RESERVAS_TYPE_ENUM.PADEL);
          this.availableHoursTenis = this.availableHours.find((item) => item.type === RESERVAS_TYPE_ENUM.TENIS);
        }
      }
    });
  }

  setStartHour(type: string, startHour: string) {
    if (type === RESERVAS_TYPE_ENUM.LOCAL) {
      this.startHour.local = startHour;
    }
    if (type === RESERVAS_TYPE_ENUM.TENIS) {
      this.startHour.tenis = startHour;
    }
    if (type === RESERVAS_TYPE_ENUM.PADEL) {
      this.startHour.padel = startHour;
    }
  }

  setEndHour(type: string, endHour: string) {
    if (type === RESERVAS_TYPE_ENUM.LOCAL) {
      this.endHour.local = endHour;
    }
    if (type === RESERVAS_TYPE_ENUM.TENIS) {
      this.endHour.tenis = endHour;
    }
    if (type === RESERVAS_TYPE_ENUM.PADEL) {
      this.endHour.padel = endHour;
    }
  }

  setComunity(comunity: ComunityModel) {
    this.selectedComunity = comunity;
    if (this.selectedComunity.extras && this.selectedComunity.extras.localActive) {
      this.localPayValue = this.selectedComunity.extras.localPay;
      this.unitPriceLocalValue = this.localPayValue ? this.selectedComunity.extras.UnitPriceLocal : null;
      this.startHour.local = this.selectedComunity.extras.hoursLocal[0];
      this.endHour.local = this.selectedComunity.extras.hoursLocal[1];
    }

    if (this.selectedComunity.extras && this.selectedComunity.extras.padelActive) {
      this.startHour.padel = this.selectedComunity.extras.hoursPadel[0];
      this.endHour.padel = this.selectedComunity.extras.hoursPadel[1];
    }

    if (this.selectedComunity.extras && this.selectedComunity.extras.tenisActive) {
      this.startHour.tenis = this.selectedComunity.extras.hoursTenis[0];
      this.endHour.tenis = this.selectedComunity.extras.hoursTenis[1];
    }
  }

  resetLocalForm() {
    setTimeout(() => {
      this.selectedComunity = null;
      this.localPayValue = false;
      this.unitPriceLocalValue = '0';
      this.startHour.local = '';
      this.endHour.local = '';
    }, 1000);
  }

  resetPadelForm() {
    setTimeout(() => {
      this.selectedComunity = null;
      this.startHour.padel = '';
      this.endHour.padel = '';
    }, 1000);
  }

  resetTenisForm() {
    setTimeout(() => {
      this.selectedComunity = null;
      this.startHour.tenis = '';
      this.endHour.tenis = '';
    }, 1000);
  }

  makeRequestUpdateLocal() {
    if (this.adminUser && this.selectedComunity && this.startHour && this.endHour) {
      this.modal.confirm({
        nzTitle: 'MODIFICAR LOCAL',
        nzContent: `¿ Va a modificar la configuración del local para la comunidad: ${this.selectedComunity.name}?`,
        nzOkText: 'Confirmar',
        nzOkType: 'primary',
        nzOnOk: () => {
          const dto = {
            userId: this.adminUser.id,
            currentUserId: this.adminUser.id,
            code_comunity: this.selectedComunity.code,
            startHour: this.startHour.local,
            endHour: this.endHour.local,
            unitPrice: this.unitPriceLocalValue,
            localPay: 1
          };
          this.store.dispatch(new UpdateLocalRequest(dto));
          this.resetLocalForm();
        },
        nzCancelText: 'Cancelar',
        nzOnCancel: () => {}
      });
    } else {
    }
  }

  makeRequestUpdatePadel() {
    if (this.adminUser && this.selectedComunity && this.startHour && this.endHour) {
      this.modal.confirm({
        nzTitle: 'MODIFICAR PÁDEL',
        nzContent: `¿ Va a modificar la configuración del pádel para la comunidad: ${this.selectedComunity.name}?`,
        nzOkText: 'Confirmar',
        nzOkType: 'primary',
        nzOnOk: () => {
          const dto = {
            userId: this.adminUser.id,
            currentUserId: this.adminUser.id,
            code_comunity: this.selectedComunity.code,
            startHour: this.startHour.padel,
            endHour: this.endHour.padel
          };
          this.store.dispatch(new UpdatePadelRequest(dto));
          this.resetPadelForm();
        },
        nzCancelText: 'Cancelar',
        nzOnCancel: () => {}
      });
    } else {
    }
  }

  makeRequestUpdateTenis() {
    if (this.adminUser && this.selectedComunity && this.startHour && this.endHour) {
      this.modal.confirm({
        nzTitle: 'MODIFICAR TENIS',
        nzContent: `¿ Va a modificar la configuración del tenis para la comunidad: ${this.selectedComunity.name}?`,
        nzOkText: 'Confirmar',
        nzOkType: 'primary',
        nzOnOk: () => {
          const dto = {
            userId: this.adminUser.id,
            currentUserId: this.adminUser.id,
            code_comunity: this.selectedComunity.code,
            startHour: this.startHour.tenis,
            endHour: this.endHour.tenis
          };
          this.store.dispatch(new UpdateTenisRequest(dto));
          this.resetTenisForm();
        },
        nzCancelText: 'Cancelar',
        nzOnCancel: () => {}
      });
    } else {
    }
  }
}
