import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ModalService } from '../../services/modal/modal.service';
import { LoadingModal } from '../../views/home/store/home.actions';

@Injectable()
export class LoadingSpinnerInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store.dispatch(new LoadingModal(true));
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.store.dispatch(new LoadingModal(false));
          }
        },
        (_err: any) => {
          // tslint:disable-next-line:no-identical-functions
          this.store.dispatch(new LoadingModal(false));
        }
      )
    );
  }
}
