import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { NzModalService } from 'ng-zorro-antd';
import { Observable } from 'rxjs';
import { ThorManagementData } from '../../../common/dtos/thor-management-data.dto';
import { AdminUserModel } from '../../../model/admin-user.model';
import { CompanyModel } from '../../../model/company.model';
import { ComunidadModel } from '../../../model/comunidad.model';
import { AlertService } from '../../../services/alert/alert.service';
import { CreateAdminUser, ThorCreateCompany, ThorDeleteAdminUser, ThorResendActivate, ThorUpdateAdminUser } from '../../home/store/home.actions';

const EMAIL_PATTERN = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';
@Component({
  selector: 'app-manage-companies',
  templateUrl: './manage-companies.component.html',
  styleUrls: ['./manage-companies.component.scss']
})
export class ManageCompaniesComponent implements OnInit {
  @Select((state) => state.core.adminUser) adminUser$: Observable<any>;
  @Select((state) => state.core.THORManagementData) data$: Observable<any>;
  data: ThorManagementData;
  adminUser: AdminUserModel;
  availableRoles = [];
  availablePages = [];
  cities = [];
  selectedRoles = [];
  selectedCompany: CompanyModel;
  selectedCompanyName = null;
  selectedCompanyUsers: AdminUserModel[];
  selectedCountry: any;
  selectedCity: any;
  selectedComunities = [];
  companyForm: FormGroup;
  userForm: FormGroup;
  isCreateUser = false;
  isEditUser = false;
  isCreateCompany = false;
  adminUserEdit: AdminUserModel;

  constructor(private fb: FormBuilder, private store: Store, private modal: NzModalService, private alertService: AlertService) {
    this.initForm();
  }

  get isTHOR() {
    return this.adminUser.mainRole === 'THOR';
  }

  get isCompanyFormValid() {
    return this.companyForm.valid;
  }

  get companies() {
    return this.data && this.data.companies ? this.data.companies : [];
  }

  get comunities() {
    return this.data && this.data.comunities ? this.data.comunities : [];
  }

  get countries() {
    return this.data && this.data.availableCountries ? this.data.availableCountries : [];
  }

  get totalCompanies() {
    return this.companies.length;
  }

  get totalUsers() {
    return this.selectedCompanyUsers.length;
  }

  ngOnInit() {
    this.adminUser$.subscribe((adminUser) => {
      if (adminUser) {
        this.adminUser = adminUser;
      }
    });
    this.data$.subscribe((data) => {
      if (data) {
        this.data = data;
      }
    });
  }

  resetUserForm() {
    this.isCreateUser = false;
    this.isEditUser = false;
    this.userForm.reset();
  }

  initForm() {
    this.companyForm = this.fb.group({
      name: [null, [Validators.required]],
      responsibleName: [null, [Validators.required]],
      responsibleEmail: ['', Validators.compose([Validators.required, Validators.pattern(EMAIL_PATTERN)])],
      cif: ['', [Validators.required]],
      tmpCountry: ['', [Validators.required]],
      country: ['', [Validators.required]],
      tmpCity: ['', [Validators.required]],
      city: ['', [Validators.required]],
      address: [null, [Validators.required]],
      postalCode: [null, [Validators.required]],
      phone: ['', []],
      logoUrl: ['', []],
      usersLimit: [null, [Validators.required]],
      comunities: [[], [Validators.required]]
    });

    this.userForm = this.fb.group({
      company: [null, [Validators.required]],
      email: ['', Validators.compose([Validators.required, Validators.pattern(EMAIL_PATTERN)])],
      name: ['', [Validators.required]],
      phone: ['', []],
      mainRole: [null, [Validators.required]],
      canEdit: [false, [Validators.required]],
      roles: [[], [Validators.required]],
      comunities: [[], [Validators.required]]
    });
  }

  editUser(adminUser: AdminUserModel) {
    this.adminUserEdit = adminUser;
    /*     if (adminUser.permissions.pagesAllowed && adminUser.permissions.pagesAllowed.length) {
      adminUser.permissions.pagesAllowed.forEach((page) => {
        this.onPageCheckedChange(page);
      });
    } */
    this.userForm = this.fb.group({
      company: [adminUser.company || { id: adminUser.companyId }, [Validators.required]],
      email: [adminUser.email, Validators.compose([Validators.required, Validators.pattern(EMAIL_PATTERN)])],
      name: [adminUser.name, [Validators.required]],
      phone: [adminUser.phone || '', []],
      mainRole: [adminUser.mainRole || null, [Validators.required]],
      canEdit: [null, [Validators.required]],
      roles: [adminUser.roles || [], [Validators.required]],
      comunities: [null, [Validators.required]]
    });

    this.isEditUser = true;
  }

  resetForm() {
    this.userForm.reset();
    this.companyForm.reset();
    this.selectedCompany = null;
    this.selectedCountry = null;
    this.selectedCity = null;
    this.selectedCompanyUsers = null;
    this.selectedCompanyName = null;
    this.selectedRoles = null;
    this.isCreateCompany = false;
    this.isCreateUser = false;
  }

  selectCompany(company) {
    if (company) {
      this.selectedCompany = company;
      this.selectedCompanyName = company.name;
      this.selectedCompanyUsers = company.users;
      this.setUserCompany(company);
    }
  }

  editCompany(company) {}

  onCompanyComunityCheckedChange(comunity: ComunidadModel) {
    this.updateCompanyComunities(comunity);
  }

  onUserComunityCheckedChange(comunity: ComunidadModel) {
    this.updateUserComunities(comunity);
  }

  onPageCheckedChange(page) {
    this.updateUserRoles(page);
  }

  createCompany() {
    if (this.companyForm.invalid) {
      this.companyForm.markAsDirty();
      return;
    }
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: `¿ Va a crear un nuevo cliente : ${this.companyForm.get('name').value}?`,
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        const dto = {
          currentUserId: this.adminUser.id,
          userId: this.adminUser.id,
          ...this.companyForm.value
        };
        this.store.dispatch(new ThorCreateCompany(dto));
        this.resetForm();
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }

  createUser() {
    if (this.userForm.invalid) {
      this.userForm.markAsDirty();
      return;
    }
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: `¿ Va a crear un nuevo usuario con el rol: ${this.userForm.get('mainRole').value}?`,
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        const companyId = (this.userForm.get('company').value as CompanyModel).id;
        const canEdit = this.userForm.get('canEdit').value;
        if (canEdit) {
          this.updateUserRoles('EDIT');
        }
        const dto = {
          currentUserId: this.adminUser.id,
          userId: this.adminUser.id,
          companyId,
          ...this.userForm.value
        };
        this.store.dispatch(new CreateAdminUser(dto));
        this.resetForm();
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }

  saveEditedUser() {
    if (this.userForm.invalid) {
      this.userForm.markAsDirty();
      return;
    }
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: `¿ Va a modificar los datos del usuario: ${this.adminUser.name} con el rol: ${this.adminUser.mainRole}?`,
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        const mainrRole = this.userForm.get('mainRole').value || this.adminUserEdit.mainRole;
        this.updateUserRoles(mainrRole);

        const canEdit = this.userForm.get('canEdit').value;
        if (canEdit) {
          this.updateUserRoles('EDIT');
        }

        const phone = this.userForm.get('phone').value;
        const comunities = this.userForm.get('comunities').value;
        const roles = this.userForm.get('roles').value;

        const dto = {
          phone,
          comunities,
          roles,
          currentUserId: this.adminUser.id,
          userId: this.adminUserEdit.id
        };
        this.store.dispatch(new ThorUpdateAdminUser(dto));
        this.resetForm();
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }

  resendActivate(user: AdminUserModel) {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: `¿ Va a reenviar el email de activación de cuenta a : ${user.name}?`,
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.store.dispatch(new ThorResendActivate(user.id));
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }

  deleteUser(user: AdminUserModel) {
    if (!user) {
      return;
    }
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: `¿ Va a suprimir el  usuario ${user.name} con el rol: ${user.mainRole}?`,
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        const dto = {
          currentUserId: this.adminUser.id,
          userId: user.id
        };
        this.store.dispatch(new ThorDeleteAdminUser(dto));
        this.resetForm();
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }

  toggleCreateCompany() {
    this.isCreateCompany = !this.isCreateCompany;
  }

  toggleCreateUser() {
    this.isCreateUser = !this.isCreateUser;
  }

  setUserCompany(company) {
    this.userForm.controls.company.patchValue(company);
  }

  setCountry(country) {
    if (country) {
      this.selectedCountry = country;
      this.cities = this.selectedCountry.cities;
      this.companyForm.controls.country.patchValue(country.value);
    }
  }

  setCity(city) {
    if (city) {
      this.selectedCity = city;
      this.companyForm.controls.city.patchValue(city.value);
    }
  }

  setRole(role) {
    this.updateUserRoles(role);
  }

  updateCompanyComunities(comunity: ComunidadModel) {
    const index = this.selectedComunities.findIndex((selCom) => selCom === comunity.code);
    if (index > -1) {
      this.selectedComunities.splice(index, 1);
    } else {
      this.selectedComunities.push(comunity.code);
    }
    this.companyForm.controls.comunities.patchValue(this.selectedComunities);
  }

  updateUserComunities(comunity: ComunidadModel) {
    const index = this.selectedComunities.findIndex((selCom) => selCom === comunity.code);
    if (index > -1) {
      this.selectedComunities.splice(index, 1);
    } else {
      this.selectedComunities.push(comunity.code);
    }
    this.userForm.controls.comunities.patchValue(this.selectedComunities);
  }

  updateUserRoles(role: string) {
    this.selectedRoles = Object.assign([], this.selectedRoles);
    const index = this.selectedRoles.findIndex((selRole) => selRole === role);
    if (index > -1) {
      this.selectedRoles.splice(index, 1);
    } else {
      this.selectedRoles.push(role);
    }
    this.userForm.controls.roles.patchValue(this.selectedRoles);
  }
}
