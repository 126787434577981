import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { NzModalService } from 'ng-zorro-antd';
import { Observable } from 'rxjs';
import { AdminUserModel } from '../../model/admin-user.model';
import { ComunidadModel } from '../../model/comunidad.model';
import { DocumentModel } from '../../model/document.model';
import { UserModel } from '../../model/vecino.model';
import { DeleteDocument, GetDocumentsByType, GetFilesTypes } from '../home/store/home.actions';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  @Select((state) => state.core.adminUser) adminUser$: Observable<any>;
  @Select((state) => state.core.filesTypes) filesTypes$: Observable<any>;
  @Select((state) => state.core.afterAddDocument) afterAddDocument$: Observable<any>;
  @Select((state) => state.core.documents) documents$: Observable<any>;
  @Select((state) => state.core.users) users$: Observable<any>;
  @Select((state) => state.core.selectedComunity) selectedComunity$: Observable<any>;

  adminUser: AdminUserModel;
  selectedComunity: ComunidadModel;
  filesTypes = [];
  selectedFileType;
  originalDocuments: DocumentModel[] = [];
  documents: DocumentModel[] = [];
  addingDocument = false;
  users: UserModel[] = [];
  searchValue = '';
  selectedDocument: DocumentModel;

  constructor(private store: Store, private modal: NzModalService) {}

  ngOnInit() {
    this.adminUser$.subscribe((adminUser) => {
      if (adminUser) {
        this.adminUser = adminUser;
      }
    });
    this.selectedComunity$.subscribe((selectedComunity) => {
      if (selectedComunity) {
        this.selectedComunity = selectedComunity;
        this.clearSelection();
      }
    });
    this.users$.subscribe((users) => {
      if (users && users.length > 0) {
        this.users = users;
      }
    });
    this.filesTypes$.subscribe((filesTypes) => {
      if (filesTypes && filesTypes.length > 0) {
        this.filesTypes = filesTypes;
      } else {
        this.filesTypes = [];
      }
    });
    this.documents$.subscribe((documents) => {
      if (documents && documents.length > 0) {
        this.documents = [];
        this.originalDocuments = documents;
        documents.forEach((doc: DocumentModel) => {
          let combinedEmails = '';
          if (doc.users && doc.users.length > 0) {
            doc.users.forEach((userId) => {
              const userExist = this.users.find((user) => user.id === userId);
              if (userExist) {
                combinedEmails += combinedEmails === '' ? ` ${userExist.email} ` : ` ,${userExist.email}`;
              }
            });
          }
          const mappedDoc: DocumentModel = {
            ...doc,
            audiencia: combinedEmails !== '' ? combinedEmails : 'comunidad'
          };
          this.documents.push(mappedDoc);
        });
      } else {
        this.documents = [];
        this.originalDocuments = [];
      }
    });
    this.clearSelection();
  }

  refresh() {
    if (this.selectedFileType) {
      this.documents = [];
      this.store.dispatch(new GetDocumentsByType(this.selectedFileType));
    }
  }

  onCloseDetail(event) {
    this.selectedDocument = null;
  }
  selectDocument(doc) {
    this.selectedDocument = doc;
  }
  onCloseAddDocument(event) {
    this.addingDocument = false;
  }
  clearSelection() {
    this.selectedFileType = null;
    this.documents = [];
    this.store.dispatch(new GetFilesTypes());
  }

  resetSearch(): void {
    this.searchValue = '';
    this.search('');
  }

  search(value: string): void {
    this.searchValue = value;
    setTimeout(() => {
      this.documents = this.originalDocuments.filter((item: DocumentModel) =>
        item.name
          .trim()
          .toLowerCase()
          .includes(this.searchValue.trim().toLowerCase())
      );
    }, 500);
  }

  getUser(userId: number): UserModel {
    return this.users.find((user) => user.id === userId);
  }

  onFileTypeChange(event) {
    this.addingDocument = false;
    this.store.dispatch(new GetDocumentsByType(event.value));
  }
  clearDocumentViews() {
    this.selectedFileType = null;
    this.addingDocument = null;
    this.documents = [];
  }

  openNewDocument() {
    this.addingDocument = true;
  }

  deleteDocument(document) {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: `¿Está seguro que quiere borrar el  documento: ${document.name}?`,
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.store.dispatch(new DeleteDocument(document.id, document.type));
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }
}
