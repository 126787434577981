import { Injectable } from '@angular/core';

@Injectable()
export class ModalService {
  visibleLoadingModal = false;
  visiblePhoneModal = false;
  visibleServiceModal = false;
  visibleSettingsModal = false;
  visibleSessionExpiredModal = false;
  visibleSessionLogoutModal = false;
  alert = { success: true, visible: false };
  DEFAULT_ALERT_TIMER = 3000;
  constructor() {}

  toggleSessionLogoutModal(state: boolean) {
    this.visibleSessionLogoutModal = state;
  }

  toggleSessionExpiredModal(state: boolean) {
    this.visibleSessionExpiredModal = state;
  }

  toggleSettingsModal(state: boolean) {
    this.visibleSettingsModal = state;
  }

  get isLoadingModal() {
    return this.visibleLoadingModal;
  }

  showPhoneModal() {
    this.visiblePhoneModal = true;
  }
  hidePhoneModal() {
    this.visiblePhoneModal = false;
  }
  showServiceModal() {
    this.visibleServiceModal = true;
  }
  hideServiceModal() {
    this.visibleServiceModal = false;
  }
  showAlert(success: boolean, timer?: number) {
    const TIMER = timer ? timer : this.DEFAULT_ALERT_TIMER;
    this.alert.success = success;
    this.alert.visible = true;
    setTimeout(() => {
      this.alert.visible = false;
    }, TIMER);
  }

  clearAll() {
    this.visibleLoadingModal = false;
    this.visiblePhoneModal = false;
    this.visibleServiceModal = false;
    this.visibleSettingsModal = false;
    this.visibleSessionExpiredModal = false;
  }
}
