import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AdminUserModel } from '../../model/admin-user.model';
import { ComunidadModel } from '../../model/comunidad.model';
import { SideNavMenuItem } from '../../model/sidenav-menu-item.model';
import { ModalService } from '../../services/modal/modal.service';
import { Logout, MyComunities, MyManagementData, SelectComunity } from '../../views/home/store/home.actions';
import { DEFAULT_PLAN_INFOS_ICONS, SIDENAV_MENU_ITEMS, LOGOUT_ICON } from './sidenav.constants';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd';
import { PAGES_TYPE_ENUM } from '../../common/enums/pages-type.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { PLANS_TYPE_ENUM } from '../../common/enums/plans-type.enum';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {
  @Select((state) => state.core.selectedComunity) selectedComunity$: Observable<any>;
  @Select((state) => state.core.comunities) comunities$: Observable<any>;
  @Select((state) => state.core.adminUser) adminUser$: Observable<any>;
  menuItems: SideNavMenuItem[] = [];
  menuItemActive: SideNavMenuItem;
  expanded = true;
  selectedComunity: ComunidadModel;
  selectedComunityName = '';
  adminUser: AdminUserModel;
  environment = environment;
  todayDate = null;
  planInfosIcons = DEFAULT_PLAN_INFOS_ICONS;
  logoutIcon = LOGOUT_ICON;

  constructor(
    public dialog: MatDialog,
    private store: Store,
    public modalService: ModalService,
    private modal: NzModalService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.menuItems = Object.assign([], SIDENAV_MENU_ITEMS);
  }

  get pageTitle() {
    return this.menuItemActive.label;
  }

  get isIcomunityPlusPlan() {
    return this.comunityAndExtrasExist && this.selectedComunity.extras.plan === PLANS_TYPE_ENUM.PLUS;
  }

  get comunityAndExtrasExist() {
    return this.selectedComunity && this.selectedComunity.extras;
  }

  get isSomeReservasActive() {
    return (
      this.selectedComunity.extras.localActive ||
      this.selectedComunity.extras.padelActive ||
      this.selectedComunity.extras.tenisActive ||
      this.selectedComunity.extras.piscinaActive ||
      this.selectedComunity.extras.solariumActive
    );
  }

  ngOnDestroy() {
    this.menuItems = Object.assign([], SIDENAV_MENU_ITEMS);
  }

  ngOnInit() {
    this.menuItems = Object.assign([], SIDENAV_MENU_ITEMS);
    this.setupActiveRoute();
    this.loadTodayDate();
    this.selectedComunity$.subscribe((selectedComunity) => {
      this.setupSelectedComunity(selectedComunity);
    });
    this.adminUser$.subscribe((adminUser) => {
      if (adminUser) {
        this.adminUser = adminUser;
      }
    });
    if (this.adminUser) {
      this.store.dispatch(new MyComunities(this.adminUser.id));
    }
    if (this.selectedComunity) {
      this.setupSelectedComunity(this.selectedComunity);
    }
  }

  setupActiveRoute() {
    const activeRoute = this.router.url;
    if (activeRoute && activeRoute !== '') {
      const activeMenuItem: SideNavMenuItem = this.menuItems.find((menu) => activeRoute.includes(menu.route));
      this.setActiveMenu(activeMenuItem);
    }
  }

  setupSelectedComunity(selectedComunity) {
    if (selectedComunity) {
      this.selectedComunity = selectedComunity;
      this.selectedComunityName = selectedComunity.name;
    }
    this.setupMenuItems();
  }

  menuClick(menuItem) {
    this.setActiveMenu(menuItem);
  }

  toggleSideNav() {
    this.expanded = !this.expanded;
  }

  setActiveMenu(activeMenu: SideNavMenuItem) {
    this.menuItemActive = activeMenu;
    this.menuItems.forEach((menu) => (menu.active = false));
    activeMenu.active = true;
  }

  onSelected(comunity: ComunidadModel) {
    this.selectedComunity = comunity;
    this.store.dispatch(new SelectComunity(comunity));
    this.setupMenuItems();
    this.navigateHome();
  }

  navigateHome() {
    this.setActiveMenu(this.menuItems[0]);
    this.router.navigateByUrl('home');
  }

  loadTodayDate() {
    this.todayDate = moment(new Date())
      .locale(environment.defaultLang)
      .format('ll');
  }

  logout() {
    this.modalService.toggleSessionLogoutModal(true);
    this.navigateHome();
  }

  setupMenuItems() {
    // THOR
    if (this.adminUser && this.adminUser.mainRole === PAGES_TYPE_ENUM.THOR) {
      this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.THOR).hidden = false;
    } else {
      this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.THOR).hidden = true;
    }

    // SELECTED COMUNITY
    if (this.adminUser && this.selectedComunity) {
      this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.HOME).hidden = false;
      this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.GESTION).hidden = false;
      this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.FACTURAS).hidden = false;
      this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.AJUSTES).hidden = false;
    } else {
      this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.GESTION).hidden = true;
      this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.FACTURAS).hidden = true;
    }

    // COMUNITDAD EXTRAS
    if (this.adminUser && this.comunityAndExtrasExist) {
      if (this.selectedComunity.extras.noticiasActive && this.adminUser.roles.includes(PAGES_TYPE_ENUM.NOTICIAS)) {
        this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.NOTICIAS).hidden = false;
      } else {
        this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.NOTICIAS).hidden = true;
      }
      if (this.selectedComunity.extras.incidenciasActive && this.adminUser.roles.includes(PAGES_TYPE_ENUM.INCIDENCIAS)) {
        this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.INCIDENCIAS).hidden = false;
      } else {
        this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.INCIDENCIAS).hidden = true;
      }
      if (this.selectedComunity.extras.documentoActive && this.adminUser.roles.includes(PAGES_TYPE_ENUM.DOCUMENTOS)) {
        this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.DOCUMENTOS).hidden = false;
      } else {
        this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.DOCUMENTOS).hidden = true;
      }
      if (this.isSomeReservasActive && this.adminUser.roles.includes(PAGES_TYPE_ENUM.RESERVAS)) {
        this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.RESERVAS).hidden = false;
      } else {
        this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.RESERVAS).hidden = true;
      }
    } else {
      this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.NOTICIAS).hidden = true;
      this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.INCIDENCIAS).hidden = true;
      this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.DOCUMENTOS).hidden = true;
      this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.RESERVAS).hidden = true;
    }

    // CAN EDIT
    if (this.adminUser && this.adminUser.canEdit) {
      this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.AJUSTES).hidden = false;
      this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.FACTURAS).hidden = false;
    } else {
      this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.AJUSTES).hidden = true;
      this.menuItems.find((menu) => menu.name === PAGES_TYPE_ENUM.FACTURAS).hidden = true;
    }
  }
}
