import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComunityModel } from '../../model/comunity.model';
import { HttpService } from '../../services/http/http.service';
import { SessionService } from '../../services/session/session.service';
import { Store } from '@ngxs/store';
import { ClearAllStores, SaveAdminUser, SaveComunities, SaveTokenV2 } from '../home/store/home.actions';
import { AdminUserModel } from '../../model/admin-user.model';
import { environment } from '../../../environments/environment';
import { LOCAL } from './login.local.constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  logged = false;

  model: any = {};
  loading = false;
  returnUrl: string;
  admin_info: ComunityModel;
  loginError = false;
  listComunity: ComunityModel[];
  logoIcon = 'assets/new-images/logo-no-text.png';
  version = environment.version;
  local = LOCAL;

  constructor(private router: Router, private sessionService: SessionService, private httpService: HttpService, private store: Store) {}

  ngOnInit() {
    this.hasSession();
  }

  login() {
    this.store.dispatch(new ClearAllStores());
    this.httpService.adminLoginV2(this.model.username, this.model.password).subscribe(
      (response) => {
        if (response && response.user && response.token) {
          const comunities = response.comunities ? response.comunities : [];
          const adminUser: AdminUserModel = new AdminUserModel(response.user);
          this.store.dispatch(new SaveTokenV2(response.token));
          this.store.dispatch(new SaveAdminUser(adminUser));
          this.store.dispatch(new SaveComunities(comunities));
          this.sessionService.login();
        }
      },
      (error) => {
        this.loginError = true;
      }
    );
  }

  hasSession() {
    if (this.sessionService.isLoggedIn()) {
      this.router.navigate(['home']);
    } else {
      this.sessionService.clearAll();
    }
  }

  fastLogin() {
    this.store.dispatch(new ClearAllStores());
    this.httpService.adminLoginV2(this.local.email, this.local.password).subscribe(
      (response) => {
        if (response && response.user && response.token) {
          const comunities = response.comunities ? response.comunities : [];
          const adminUser: AdminUserModel = new AdminUserModel(response.user);
          this.store.dispatch(new SaveTokenV2(response.token));
          this.store.dispatch(new SaveAdminUser(adminUser));
          this.store.dispatch(new SaveComunities(comunities));
          this.sessionService.login();
        }
      },
      (error) => {
        this.loginError = true;
      }
    );
  }
}
