import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material';
import { Select, Store } from '@ngxs/store';
import { NzModalService } from 'ng-zorro-antd';
import { Observable } from 'rxjs';
import { AdminUserModel } from '../../../model/admin-user.model';
import { ComunidadModel } from '../../../model/comunidad.model';
import { UserModel } from '../../../model/vecino.model';
import { LocalModel } from '../../../models/local.model';
import { ExporterService } from '../../../services/exporter/exporter.service';
import { StorageService } from '../../../services/storage/storage.service';
import { UtilsService } from '../../../services/utils.service';
import { GetLocals } from '../../home/store/home.actions';
import { FILTRO_FECHA_ITEMS, SET_DATE_PNPUT } from '../booking.constants';

@Component({
  selector: 'app-table-local',
  templateUrl: './table-local.component.html',
  styleUrls: ['./table-local.component.scss']
})
export class TableLocalComponent implements OnInit {
  @Select((state) => state.core.locals) locals$: Observable<any>;
  @Input() users: UserModel[] = [];
  @Input() adminUser: AdminUserModel;
  @Input() selectedComunity: ComunidadModel;
  data: LocalModel[] = null;
  originalData: LocalModel[] = null;
  searchValue = '';
  selectedItem: any;
  selectedFechaOption = '';
  filtroFechaItems = FILTRO_FECHA_ITEMS;
  setDateInput = SET_DATE_PNPUT;
  constructor(
    private store: Store,
    private datePipe: DatePipe,
    private modal: NzModalService,
    private storageService: StorageService,
    private exporterService: ExporterService,
    private utilService: UtilsService
  ) {}

  ngOnInit() {
    this.locals$.subscribe((items) => {
      if (items && items.length > 0) {
        this.data = this.mapLocalBooking(items);
        this.originalData = Object.assign(this.data);
      } else {
        this.data = [];
      }
    });
    this.refresh();
  }
  exitDetail(done) {
    if (done) {
      this.refresh();
    }
    this.selectedItem = null;
  }
  selectItemLocal(item) {
    this.selectedItem = item;
    this.selectedItem.comunidad = this.selectedComunity;
  }

  clearFilters() {
    this.resetSearch();
    this.refresh();
    this.selectedFechaOption = '';
  }

  refresh() {
    this.store.dispatch(new GetLocals());
  }
  resetSearch(): void {
    this.searchValue = '';
    this.search('');
  }

  search(value: string): void {
    this.searchValue = value;
    setTimeout(() => {
      this.data = this.originalData.filter((item: LocalModel) =>
        item.name
          .trim()
          .toLowerCase()
          .includes(this.searchValue.trim().toLowerCase())
      );
    }, 500);
  }
  exportExcel() {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: 'Va a exportar la lista de reservas de local con el formato excel',
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        try {
          const filename = 'export_reserva_local';
          const clonedList = this.storageService.cloneObject(this.data);
          const data: LocalModel[] = this.mappLocalDataToExcelFormat(clonedList);
          this.exporterService.exportAsExcelFile(data, filename);
        } catch (error) {
          console.log('error export ->', error);
        }
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }

  mappLocalDataToExcelFormat(list: any[]) {
    const items = [...list];
    for (const item of items) {
      item.comunidad = this.selectedComunity.name;
      item.nombre = item.name;
      item.direccion = item.adress;
      item.fecha = item.day;
      item.franjaHoraria = item.hours;
      item.horas = item.countHours;

      delete item.id;
      delete item.countHours;
      delete item.adress;
      delete item.name;
      delete item.day;
      delete item.code;
      delete item.hours;
      delete item.price;
      delete item.active;
    }
    return items;
  }

  mapLocalBooking(items) {
    const result: LocalModel[] = [];
    if (items && items.length > 0 && this.users && this.users.length > 0) {
      for (const book of items) {
        const size = book.hours.length;
        const price = `${size}`;
        const countHours = `${size}`;
        const hours = `${book.hours[0]}h - ${Number(book.hours[size - 1]) + 1}h`;
        const item: LocalModel = {
          countHours,
          id: book._id,
          code: book.code,
          name: '-',
          adress: '-',
          email: '-',
          price: price,
          day: book.day,
          hours: hours,
          active: book.active,
          expired: book.expired,
          createdAt: book.createdAt
        };
        for (const user of this.users) {
          if (user.code_house === book.userId) {
            item.name = user.name;
            item.email = user.email;
            item.adress = user.adress;
          }
        }
        const exist = result.findIndex((el) => el.id === item.id);
        if (exist === -1) {
          result.push(item);
        }
      }
    }
    return result;
  }

  onDateFilterChange(event) {
    this.data = this.utilService.onDateFilterChange(event, this.originalData);
    this.clearDatePickerInput();
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    const value = event.value;
    const result = [];
    if (type === 'in') {
      this.setDateInput.in.bool = true;
      this.setDateInput.in.value = value;
    } else if (type === 'fin') {
      this.setDateInput.fin.bool = true;
      this.setDateInput.fin.value = value;
    }
    if (this.setDateInput.in.bool && this.setDateInput.fin.bool) {
      this.originalData.forEach((item) => {
        const fechatime = new Date(item.day).getTime();
        const intime = this.setDateInput.in.value.getTime();
        const fintime = this.setDateInput.fin.value.getTime();
        if (intime < fechatime && fechatime < fintime) {
          result.push(item);
        }
      });
      this.data = result;
    }
  }
  clearDatePickerInput() {
    this.setDateInput = {
      in: {
        bool: false,
        value: new Date()
      },
      fin: {
        bool: false,
        value: new Date()
      }
    };
  }

  formatDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
  }
}
