import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { en_US, NzI18nService, NzModalService } from 'ng-zorro-antd';
import { Observable } from 'rxjs';
import { ThorManagementData } from '../../../common/dtos/thor-management-data.dto';
import { AdminUserModel } from '../../../model/admin-user.model';
import { CompanyModel } from '../../../model/company.model';
import { ComunidadModel } from '../../../model/comunidad.model';
import { FacturaModel } from '../../../model/factura.model';
import { CreateFacturaComunity, DeleteFacturaComunity, GetFacturasByComunity, ResendFacturaEmail } from '../../home/store/home.actions';

@Component({
  selector: 'app-manage-comunities',
  templateUrl: './manage-comunities.component.html',
  styleUrls: ['./manage-comunities.component.scss']
})
export class ManageComunitiesComponent implements OnInit {
  @Select((state) => state.core.adminUser) adminUser$: Observable<any>;
  @Select((state) => state.core.THORManagementData) data$: Observable<any>;
  @Select((state) => state.core.companyFacturas) companyFacturas$: Observable<any>;
  @Select((state) => state.core.comunityFacturas) comunityFacturas$: Observable<any>;
  data: ThorManagementData;
  adminUser: AdminUserModel;
  selectedCompany: CompanyModel;
  comunities: ComunidadModel[];
  selectedComunity: ComunidadModel;
  selectedFacturaId;
  companyFacturas: FacturaModel[] = [];
  comunityFacturas: FacturaModel[] = [];
  facturaForm: FormGroup;
  isCreateFactura = false;

  constructor(private fb: FormBuilder, private store: Store, private modal: NzModalService, private i18n: NzI18nService) {
    this.initForm();
    this.i18n.setLocale(en_US);
  }

  get isTHOR() {
    return this.adminUser.mainRole === 'THOR';
  }

  ngOnInit() {
    this.adminUser$.subscribe((adminUser) => {
      if (adminUser) {
        this.adminUser = adminUser;
      }
    });
    this.data$.subscribe((data) => {
      if (data) {
        this.data = data;
      }
    });
    this.companyFacturas$.subscribe((companyFacturas) => {
      if (companyFacturas) {
        this.companyFacturas = companyFacturas;
      }
    });
    this.comunityFacturas$.subscribe((comunityFacturas) => {
      if (comunityFacturas) {
        this.comunityFacturas = comunityFacturas;
      }
    });
  }

  initForm() {
    this.facturaForm = this.fb.group({
      companyId: [null, [Validators.required]],
      comunityCode: [null, [Validators.required]],
      comunityName: [null, [Validators.required]],
      amount: [null, [Validators.required]],
      plan: [null, [Validators.required]],
      month: [null, [Validators.required]],
      year: [null, [Validators.required]],
      fecha: [null, [Validators.required]],
      notify: [null, [Validators.required]]
    });
  }

  resetForm() {
    this.facturaForm.reset();
    this.isCreateFactura = false;
  }

  setCompany(company) {
    this.selectedCompany = company;
    this.comunities = Object.assign([], this.selectedCompany.comunitiesList);
    this.facturaForm.controls.companyId.patchValue(this.selectedCompany.id);
  }

  setComunity(comunity) {
    this.selectedComunity = comunity;
    if (this.selectedCompany && this.selectedComunity) {
      this.facturaForm.controls.comunityCode.patchValue(this.selectedComunity.code);
      this.facturaForm.controls.comunityName.patchValue(this.selectedComunity.name);
      const dto = {
        comunityCode: this.selectedComunity.code,
        companyId: this.selectedCompany.id
      };
      this.store.dispatch(new GetFacturasByComunity(dto));
    }
  }
  toggleCreateFactura() {
    this.isCreateFactura = !this.isCreateFactura;
  }
  createFactura() {
    if (this.facturaForm.invalid) {
      this.facturaForm.markAsDirty();
      return;
    }
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: `¿ Va a crear un nueva factura, cliente: ${this.selectedCompany.name}, comunidad: ${this.selectedComunity.name}?`,
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        const dto = {
          currentUserId: this.adminUser.id,
          userId: this.adminUser.id,
          ...this.facturaForm.value
        };
        this.store.dispatch(new CreateFacturaComunity(dto));
        this.refresh();
        this.resetForm();
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }

  deleteFactura(factura: FacturaModel) {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: `¿ Va a suprimir un factura: ${factura.name}?`,
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        const dto = {
          currentUserId: this.adminUser.id,
          userId: this.adminUser.id,
          facturaId: factura.id
        };
        this.store.dispatch(new DeleteFacturaComunity(dto));
        this.refresh();
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }

  resendFacturaEmail(factura) {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: `¿ Va a reenviar el email de factura: ${factura.name} al email: ${factura.responsibleEmail}?`,
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        const dto = {
          userId: this.adminUser.id,
          facturaID: factura.id
        };
        this.store.dispatch(new ResendFacturaEmail(dto));
        this.refresh();
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }

  refresh() {
    setTimeout(() => {
      const reloadDto = {
        comunityCode: this.selectedComunity.code,
        companyId: this.selectedCompany.id
      };
      this.store.dispatch(new GetFacturasByComunity(reloadDto));
    }, 2000);
  }
}
