import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { ModalService } from '../../services/modal/modal.service';
import { Logout } from '../../views/home/store/home.actions';

@Component({
  selector: 'app-session-logout',
  templateUrl: './session-logout.component.html',
  styleUrls: ['./session-logout.component.scss']
})
export class SessionLogoutComponent implements OnInit {
  @Output() cancelled = new EventEmitter<boolean>();

  constructor(private store: Store, private modalService: ModalService) {}

  ngOnInit() {}

  logout() {
    this.store.dispatch(new Logout());
    this.modalService.toggleSessionLogoutModal(false);
  }

  cancel() {
    this.cancelled.emit(true);
  }
}
