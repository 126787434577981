import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd';
import { environment } from '../../../../environments/environment';
import { CancelReservaLocalDto } from '../../../common/dtos/cancel-reserva-local.dto';
import { AdminUserModel } from '../../../model/admin-user.model';
import { LocalModel } from '../../../models/local.model';
import { AlertService } from '../../../services/alert/alert.service';
import { HttpService } from '../../../services/http/http.service';

@Component({
  selector: 'app-book-local',
  templateUrl: './book-local.component.html',
  styleUrls: ['./book-local.component.scss']
})
export class BookLocalComponent implements OnInit {
  @Input() data: LocalModel;
  @Input() adminUser: AdminUserModel;
  @Output() done = new EventEmitter<Boolean>(false);
  closing = false;
  selectedCancel = false;
  paymentback = false;
  constructor(private httpService: HttpService, private modal: NzModalService, private alertService: AlertService) {}

  ngOnInit() {}
  cancelReserva() {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: '¿ Está seguro que quiere cancelar esta reserva?',
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        const emails = [];
        emails.push(environment.soporteEmail);
        if (this.data.email) {
          emails.push(this.data.email);
        }
        if (this.data.comunidad.admin_email) {
          emails.push(this.data.comunidad.admin_email);
        }
        const payload: CancelReservaLocalDto = {
          emails,
          id: this.data.id,
          code: this.data.code,
          comunidad: this.data.comunidad.name,
          admin_email: this.data.comunidad.admin_email,
          email: this.data.email,
          username: this.data.name,
          adress: this.data.adress,
          fecha: this.data.day,
          hora: this.data.hours,
          paymentback: this.paymentback ? 'SI' : 'NO'
        };
        this.httpService.cancelReservaLocalAdmin(payload).subscribe(
          (response) => {
            this.cancelSuccess();
          },
          (error) => {
            this.cancelError();
          }
        );
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }
  cancelError() {
    // tslint:disable-next-line: max-line-length
    this.alertService.popNotification('error', 'Hubo un error', 'No se ha podido cancelar esta reserva, por favor intenta más tarde y si persiste,contacta con soporte');
  }
  cancelSuccess() {
    // tslint:disable-next-line: max-line-length
    this.alertService.popNotification('success', 'Reserva cancelada', 'Se ha cancelado correctamente la reserva');
    this.close(true);
  }
  makePaymentBack() {
    this.paymentback = !this.paymentback;
  }
  changeSelectCancel(event) {
    this.selectedCancel = event.checked;
    this.paymentback = false;
  }
  cancel() {
    this.done.emit(true);
  }
  close(done: boolean) {
    this.closingAnimation();
    setTimeout(() => {
      this.done.emit(done);
    }, 400);
  }

  closingAnimation() {
    this.closing = true;
  }
}
