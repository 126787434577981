import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AdminUserModel } from '../../model/admin-user.model';
import { ModalService } from '../../services/modal/modal.service';
import { MyManagementData } from '../home/store/home.actions';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @Select((state) => state.core.adminUser) adminUser$: Observable<any>;
  adminUser: AdminUserModel;

  constructor(public store: Store, private modalService: ModalService) {}

  ngOnInit() {
    this.adminUser$.subscribe((adminUser) => {
      if (adminUser) {
        this.adminUser = adminUser;
        this.store.dispatch(new MyManagementData(this.adminUser.id));
      }
    });
  }

  close() {
    this.modalService.toggleSettingsModal(false);
  }
}
