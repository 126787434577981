import { Component, Input, OnInit } from '@angular/core';
import { IncidenciaModel } from '../../../model/incidencia.model';
import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { PopImageViewer, SelectIncidencia, UpdateIncidencia } from '../../home/store/home.actions';
import { NzModalService } from 'ng-zorro-antd';
import { UserModel } from '../../../model/vecino.model';
import { AdminUserModel } from '../../../model/admin-user.model';

@Component({
  selector: 'app-incidencia',
  templateUrl: './incidencia.component.html',
  styleUrls: ['./incidencia.component.scss']
})
export class IncidenciaComponent implements OnInit {
  @Input() adminUser: AdminUserModel;
  @Select((state) => state.core.selectedIncidencia)
  selectedIncidencia$: Observable<any>;
  oldState = '';
  data: IncidenciaModel;
  users: UserModel[] = [];
  closing = false;
  closeIcon = 'assets/icon/close.png';
  constructor(private store: Store, private modal: NzModalService) {}

  ngOnInit() {
    this.selectedIncidencia$.subscribe((selectedIncidencia) => {
      if (selectedIncidencia) {
        this.data = {
          ...selectedIncidencia
        };
        this.oldState = selectedIncidencia.state === 1 ? 'pendiente' : selectedIncidencia.state === 3 ? 'en progreso' : 'solucionado';
      }
    });
  }
  popImageViewer(url: string) {
    this.store.dispatch(new PopImageViewer(url));
  }
  onChange(type: string) {
    switch (type) {
      case 'pendiente':
        this.data.state = 1;
        break;
      case 'procesando':
        this.data.state = 3;
        break;
      case 'solucionado':
        this.data.state = 2;
        break;
    }
  }
  onChangeOculto(event) {
    this.data.oculto = this.data.oculto === 1 ? 2 : 1;
  }
  save() {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: '¿ Está seguro que quiere actualizar esta incidencia ?',
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.updateAndClose();
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }

  updateAndClose() {
    this.closingAnimation();
    const newState = this.data.state === 1 ? 'pendiente' : this.data.state === 3 ? 'en progreso' : 'solucionado';
    // tslint:disable-next-line: max-line-length
    this.store.dispatch(new UpdateIncidencia(this.data.code_comunity, this.data.category, this.data.type, this.data.id, this.data.state, this.data.oculto));
  }
  close() {
    this.closingAnimation();
    setTimeout(() => {
      this.store.dispatch(new SelectIncidencia(null));
    }, 400);
  }

  closingAnimation() {
    this.closing = true;
  }
}
