import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdminUserModel } from '../../../model/admin-user.model';
import { NoticiaModel } from '../../../model/noticia.model';

@Component({
  selector: 'app-noticia',
  templateUrl: './noticia.component.html',
  styleUrls: ['./noticia.component.scss']
})
export class NoticiaComponent implements OnInit {
  @Input() data: NoticiaModel;
  @Input() adminUser: AdminUserModel;
  @Output() done = new EventEmitter<Boolean>(false);
  @Output() disable = new EventEmitter<any>(null);
  closing = false;
  constructor() {}

  ngOnInit() {}

  disableNoticia() {
    this.disable.emit(this.data);
    this.close();
  }
  close() {
    this.closingAnimation();
    setTimeout(() => {
      this.done.emit(true);
    }, 400);
  }

  closingAnimation() {
    this.closing = true;
  }
}
