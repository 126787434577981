import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from '../../services/alert/alert.service';
import { ModalService } from '../../services/modal/modal.service';

const ERROR_CODES = {
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  CONFLICT: 409,
  FORBIDDEN: 403,
  INTERNAL_SERVER: 500,
  UNAUTHORIZED: 401
};
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(public router: Router, private alertService: AlertService, public modalService: ModalService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error.name === 'HttpErrorResponse' && error.status === ERROR_CODES.NOT_FOUND) {
          // tslint:disable-next-line: max-line-length
          this.alertService.popNotification('error', 'Problema de conectividad', 'Hubo un error de conexión. Por favor vuelva a intentarlo');
        }
        if (error.name === 'HttpErrorResponse' && (error.status === ERROR_CODES.CONFLICT || ERROR_CODES.BAD_REQUEST)) {
          // tslint:disable-next-line: max-line-length
          this.alertService.popNotification('error', 'Conflicto', 'No se puede realizar esta operación, revise los datos y vuelve a intentarlo.');
        }
        if (!req.url.includes('login') && error.name === 'HttpErrorResponse' && (error.status === ERROR_CODES.UNAUTHORIZED || error.status === ERROR_CODES.FORBIDDEN)) {
          this.modalService.toggleSessionExpiredModal(true);
        }
        throw new Error(error);
      })
    );
  }

  throwError(err: any): import('rxjs/Observable').ObservableInput<any> {
    throw new Error(err);
  }
}
