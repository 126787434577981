import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ModalService } from './services/modal/modal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @Select((state) => state.core.imageViewerUrl) imageViewerUrl$: Observable<any>;
  @Select((state) => state.core.loadingModal) loadingModal$: Observable<any>;

  imageViewerLoaded = false;
  isLoading = false;

  constructor(public modalService: ModalService) {}
  ngOnInit() {
    this.imageViewerUrl$.subscribe((imageViewerUrl) => {
      this.imageViewerLoaded = !!imageViewerUrl;
    });

    this.loadingModal$.subscribe((loadingModal) => {
      setTimeout(() => {
        this.isLoading = loadingModal;
      }, 100);
    });
  }

  logoutCancelled(_event) {
    this.modalService.toggleSessionLogoutModal(false);
  }
}
