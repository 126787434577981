export enum PAGES_TYPE_ENUM {
  HOME = 'HOME',
  NOTICIAS = 'NOTICIAS',
  INCIDENCIAS = 'INCIDENCIAS',
  GESTION = 'GESTION',
  DOCUMENTOS = 'DOCUMENTOS',
  RESERVAS = 'RESERVAS',
  AJUSTES = 'AJUSTES',
  FACTURAS = 'FACTURAS',
  THOR = 'THOR'
}
