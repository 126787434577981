import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ThorManagementData } from '../../common/dtos/thor-management-data.dto';
import { AdminUserModel } from '../../model/admin-user.model';
import { GetThorManagement } from '../home/store/home.actions';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  @Select((state) => state.core.adminUser) adminUser$: Observable<any>;
  @Select((state) => state.core.THORManagementData) data$: Observable<any>;
  menus = [
    { name: 'companies', label: 'Gestión clientes', icon: 'assets/img/crud.jpeg' },
    { name: 'comunities', label: 'Gestión comunidades', icon: 'assets/img/crud.jpeg' },
    { name: 'tickets', label: 'Tickets soporte', icon: 'assets/img/chat.png' },
    { name: 'ofertas', label: 'Gestión ofertas', icon: 'assets/img/offers.png' },
    { name: 'settings-web', label: 'Ajustes web', icon: 'assets/img/panel.png' },
    { name: 'settings-mobile', label: 'Ajustes mobile', icon: 'assets/img/mobile.png' }
  ];
  selectedMenu = 'companies';
  adminUser: AdminUserModel;
  data: ThorManagementData;
  refreshIcon = 'assets/img/refresh.png';

  constructor(private store: Store) {}

  ngOnInit() {
    this.adminUser$.subscribe((adminUser) => {
      if (adminUser && adminUser.mainRole === 'THOR') {
        this.adminUser = adminUser;
        this.store.dispatch(new GetThorManagement(adminUser.id));
      }
    });

    this.data$.subscribe((data) => {
      if (data) {
        this.data = data;
      }
    });
  }

  refresh() {
    this.store.dispatch(new GetThorManagement(this.adminUser.id));
  }
  selectMenu(menu) {
    this.selectedMenu = menu.name;
  }
}
