import { CompanyModel } from './company.model';
import { ComunidadModel } from './comunidad.model';

export class AdminUserModel {
  id?: string;
  companyId?: string;
  email: string;
  name: string;
  phone: string;
  mainRole: string;
  roles: string[];
  comunities: ComunidadModel[];
  company?: CompanyModel;
  lastConnection?: Date;
  permissions?: UserPermission;
  canEdit: boolean;
  companyUsers?: any[];

  constructor(initialValues?: Partial<AdminUserModel> | null) {
    if (initialValues) {
      Object.assign(this, initialValues);
    }
  }
}

interface UserPermission {
  hasEditPermission: boolean;
  hasUsersAdminPermission: boolean;
  pagesAllowed: string[];
}
