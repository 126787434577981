import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Select((state) => state.core.christmasAnimationActive) christmasActive$: Observable<any>;

  environment = environment;
  todayDate = null;

  constructor(private store: Store) {}

  ngOnInit() {
    this.loadTodayDate();
  }

  loadTodayDate() {
    this.todayDate = moment(new Date())
      .locale(environment.defaultLang)
      .format('ll');
  }
}
