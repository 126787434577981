import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AdminUserModel } from '../../model/admin-user.model';
import { ComunityModel } from '../../model/comunity.model';
import { FacturaModel } from '../../model/factura.model';
import { GetFacturasByComunity } from '../home/store/home.actions';

@Component({
  selector: 'app-facturas',
  templateUrl: './facturas.component.html',
  styleUrls: ['./facturas.component.scss']
})
export class FacturasComponent implements OnInit {
  @Select((state) => state.core.adminUser) adminUser$: Observable<any>;
  @Select((state) => state.core.comunities) comunities$: Observable<any>;
  @Select((state) => state.core.selectedComunity) selectedComunity$: Observable<any>;
  @Select((state) => state.core.comunityFacturas) comunityFacturas$: Observable<any>;
  adminUser: AdminUserModel;
  selectedComunity: ComunityModel;
  comunityFacturas: FacturaModel[];
  comunities: ComunityModel[] = [];
  refreshIcon = 'assets/img/refresh.png';

  constructor(private store: Store) {}

  ngOnInit() {
    this.adminUser$.subscribe((adminUser) => {
      if (adminUser) {
        this.adminUser = adminUser;
      }
    });
    this.comunities$.subscribe((comunities) => {
      if (comunities) {
        this.comunities = comunities;
      }
    });
    this.selectedComunity$.subscribe((selectedComunity) => {
      if (selectedComunity) {
        this.selectedComunity = selectedComunity;
      }
    });
    this.comunityFacturas$.subscribe((comunityFacturas) => {
      if (comunityFacturas) {
        this.comunityFacturas = comunityFacturas;
      }
    });
    this.loadFacturas();
  }

  refresh() {
    this.loadFacturas();
  }

  setComunity(comunity) {
    this.selectedComunity = comunity;
    this.loadFacturas();
  }
  loadFacturas() {
    if (this.adminUser) {
      const companyId = this.adminUser.company.id;
      if (companyId && this.selectedComunity) {
        const dto = {
          companyId,
          comunityCode: this.selectedComunity.code
        };
        this.store.dispatch(new GetFacturasByComunity(dto));
      }
    }
  }
}
