export class GetNoticias {
  static readonly type = '[NOTICIAS] GetNoticias';
  constructor() {}
}
export class PopNewNoticia {
  static readonly type = '[NOTICIAS] PopNewNoticia';
  constructor(public show) {}
}

export class PopImageViewer {
  static readonly type = '[COMPONENTS] PopImageViewer';
  constructor(public url) {}
}

export class DismissImageViewer {
  static readonly type = '[COMPONENTS] DismissImageViewer';
  constructor() {}
}

export class ChristmasAnimationActive {
  static readonly type = '[ANIMATION] ChristmasAnimationActive';
  constructor(public active) {}
}
export class CancelReservaLocal {
  static readonly type = '[LOCAL] CancelReservaLocal';
  constructor(public payload) {}
}
export class AddNoticia {
  static readonly type = '[NOTICIAS] AddNoticia';
  constructor(public noticia) {}
}
export class GetDocumentsByType {
  static readonly type = '[DOCUMENTS] GetDocumentsByType';
  constructor(public type) {}
}
export class AddDocument {
  static readonly type = '[DOCUMENTS] AddDocument';
  constructor(public document) {}
}
export class DeleteDocument {
  static readonly type = '[DOCUMENTS] DeleteDocument';
  constructor(public documentId, public type) {}
}
export class AfterAddDocument {
  static readonly type = '[DOCUMENTS] AfterAddDocument';
  constructor(public action) {}
}
export class DisableNoticia {
  static readonly type = '[NOTICIAS] DisableNoticia';
  constructor(public id) {}
}
export class PopNewIncidencia {
  static readonly type = '[INCIDENCIAS] PopNewInciencia';
  constructor(public show) {}
}

export class LoadingModal {
  static readonly type = '[CORE] LoadingModal';
  constructor(public show) {}
}
export class GetIncidencias {
  static readonly type = '[INCIDENCIAS] GetIncidencias';
  constructor() {}
}
export class SelectIncidencia {
  static readonly type = '[INCIDENCIAS] SelectIncidencia';
  constructor(public incidencia) {}
}
export class SelectTraspasoIncidencia {
  static readonly type = '[INCIDENCIAS] SelectTraspasoIncidencia';
  constructor(public incidencia) {}
}
export class UpdateIncidencia {
  static readonly type = '[INCIDENCIAS] UpdateIncidencia';
  constructor(public code: string, public category: string, public type: string, public id: string, public state: string, public oculto: string) {}
}
export class GetServices {
  static readonly type = '[SERVICES] GetServices';
  constructor() {}
}
export class GetFilesTypes {
  static readonly type = '[FILES] GetFilesTypes';
  constructor() {}
}
export class GetUsers {
  static readonly type = '[USERS] GetUsers';
  constructor() {}
}
export class GetLocals {
  static readonly type = '[LOCALS] GetLocals';
  constructor() {}
}
export class GetPadels {
  static readonly type = '[PADELS] GetPadels';
  constructor() {}
}
export class GetPhones {
  static readonly type = '[PHONES] GetPhones';
  constructor() {}
}
export class GetPiscinas {
  static readonly type = '[PHONES] GetPiscinas';
  constructor() {}
}
export class GetSolariums {
  static readonly type = '[PHONES] GetSolariums';
  constructor() {}
}
export class GetComunity {
  static readonly type = '[COMUNITY] GetComunity';
  constructor(public code: string) {}
}
export class SaveComunities {
  static readonly type = '[COMUNITY] SaveComunities';
  constructor(public comunities) {}
}

export class SaveAuthToken {
  static readonly type = '[COMUNITY] AuthToken';
  constructor(public authToken) {}
}
export class SelectComunity {
  static readonly type = '[COMUNITY] SelectComunity';
  constructor(public comunity) {}
}

export class UpdateUsersState {
  static readonly type = '[COMUNITY] UpdateUsersState';
  constructor(public id_list, public action: string) {}
}
export class ClearAllStores {
  static readonly type = '[ICOMUNITY] ClearAllStores';
  constructor() {}
}
export class GetHelpTickets {
  static readonly type = '[THOR] GetHelpTickets';
  constructor() {}
}

export class SendTicketMessage {
  static readonly type = '[THOR] SendTicketMessage';
  constructor(public ticketId: string, public origin: string, public target: string, public message: string, public isUser: boolean) {}
}
export class UpdateTicket {
  static readonly type = '[THOR] UpdateTicket';
  constructor(public ticket) {}
}
export class DeleteTicketMessage {
  static readonly type = '[THOR] DeleteTicketMessage';
  constructor(public messageId: string) {}
}
export class SaveTokenV2 {
  static readonly type = '[CORE] TokenV2';
  constructor(public tokenV2) {}
}

export class SaveAdminUser {
  static readonly type = '[CORE] AdminUser';
  constructor(public adminUser) {}
}

export class UpdateLocalRequest {
  static readonly type = '[CORE] UpdateLocalRequest';
  constructor(public dto) {}
}

export class UpdatePadelRequest {
  static readonly type = '[CORE] UpdatePadelRequest';
  constructor(public dto) {}
}

export class UpdateTenisRequest {
  static readonly type = '[CORE] UpdateTenisRequest';
  constructor(public dto) {}
}

export class Logout {
  static readonly type = '[CORE] Logout';
  constructor() {}
}
export class GetStats {
  static readonly type = '[CORE] GetStats';
  constructor() {}
}

export class GetOfertas {
  static readonly type = '[CORE] Ofertas';
  constructor() {}
}
export class MyManagementData {
  static readonly type = '[CORE] MyManagementData';
  constructor(public userId) {}
}

export class MyComunities {
  static readonly type = '[CORE] MyComunities';
  constructor(public userId) {}
}
export class CreateAdminUser {
  static readonly type = '[CORE] CreateAdminUser';
  constructor(public dto) {}
}

export class UpdateAdminUser {
  static readonly type = '[CORE] UpdateAdminUser';
  constructor(public dto) {}
}

export class DeleteAdminUser {
  static readonly type = '[CORE] DeleteAdminUser';
  constructor(public dto) {}
}

export class SendUpdatePasswordEmail {
  static readonly type = '[CORE] SendUpdatePasswordEmail';
  constructor(public dto) {}
}

export class GetOfertasTypes {
  static readonly type = '[CORE] GetOfertasTypes';
  constructor() {}
}

export class GetFacturasByComunity {
  static readonly type = '[CORE] GetFacturasByComunity';
  constructor(public dto) {}
}

export class CreateFacturaComunity {
  static readonly type = '[CORE] CreateFacturaComunity';
  constructor(public dto) {}
}

export class ResendFacturaEmail {
  static readonly type = '[CORE] ResendFacturaEmail';
  constructor(public dto) {}
}

export class DeleteFacturaComunity {
  static readonly type = '[CORE] DeleteFacturaComunity';
  constructor(public dto) {}
}

export class GetFacturasByCompany {
  static readonly type = '[CORE] GetFacturasByCompany';
  constructor(public companyId) {}
}

export class CreateOferta {
  static readonly type = '[THOR] CreateOferta';
  constructor(public payload) {}
}

export class DeleteOferta {
  static readonly type = '[THOR] DeleteOferta';
  constructor(public payload) {}
}

export class GetThorManagement {
  static readonly type = '[THOR] GetThorManagement';
  constructor(public userId) {}
}

export class ThorCreateCompany {
  static readonly type = '[THOR] ThorCreateCompany';
  constructor(public dto) {}
}

export class ThorUpdateCompany {
  static readonly type = '[THOR] ThorUpdateCompany';
  constructor(public dto) {}
}

export class ThorCreateAdminUser {
  static readonly type = '[THOR] ThorCreateAdminUser';
  constructor(public dto) {}
}

export class ThorUpdateAdminUser {
  static readonly type = '[THOR] ThorUpdateAdminUser';
  constructor(public dto) {}
}

export class ThorDeleteAdminUser {
  static readonly type = '[THOR] ThorDeleteAdminUser';
  constructor(public dto) {}
}

export class ThorResendActivate {
  static readonly type = '[THOR] ThorResendActivate';
  constructor(public userId) {}
}

/*
export class PickupFromLocker {
  static readonly type = '[CONSIGNACUSTOMER] PickupFromLocker';
  constructor(public data: PickUpCodeDto) {}
}

export class DropoffConfirmation {
  static readonly type = '[CONSIGNACUSTOMER] DropoffConfirmation';
  constructor(public data: {id: string, code: string}) {}
}
 */
