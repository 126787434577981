import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { ExporterService } from '../../services/exporter/exporter.service';
import { AlertService } from '../../services/alert/alert.service';
import { StorageService } from '../../services/storage/storage.service';
import { HttpService } from '../../services/http/http.service';
import { ComunidadModel } from '../../model/comunidad.model';
import { ServiceModel } from '../../model/service.model';
import { IncidenciaModel } from '../../model/incidencia.model';
// tslint:disable-next-line: import-blacklist
import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { GetIncidencias, GetServices, GetUsers, PopImageViewer, SelectIncidencia, SelectTraspasoIncidencia } from '../home/store/home.actions';
import { UserModel } from '../../model/vecino.model';
import { NzModalService } from 'ng-zorro-antd';
import { UtilsService } from '../../services/utils.service';
import { AdminUserModel } from '../../model/admin-user.model';
const ONE_DAY = 1000 * 60 * 60 * 24;
declare var jsPDF: any;

@Component({
  selector: 'app-incidencias',
  templateUrl: './incidencias.component.html',
  styleUrls: ['./incidencias.component.scss']
})
export class IncidenciasComponent implements OnInit {
  @Select((state) => state.core.incidencias) incidencias$: Observable<any>;
  @Select((state) => state.core.selectedIncidencia)
  selectedIncidencia$: Observable<any>;
  @Select((state) => state.core.selectedTraspasoIncidencia)
  selectedTraspasoIncidencia$: Observable<any>;
  @Select((state) => state.core.selectedComunity) selectedComunity$: Observable<any>;
  @Select((state) => state.core.users) users$: Observable<any>;
  @Select((state) => state.core.services) services$: Observable<any>;
  @Select((state) => state.core.adminUser) adminUser$: Observable<any>;

  adminUser: AdminUserModel;
  refreshIcon = 'assets/img/refresh.png';
  placeholder = 'assets/img/placeholder.png';
  loading = false;
  nb_incidencias = 0;
  minDate = new Date(2018, 0, 1);
  maxDate = new Date(2019, 0, 1);
  date = new Date();
  fechaIn: string;
  fechaFin: string;
  model: Model = {
    category: '',
    type: '',
    description: ''
  };

  types = [
    { value: 'averia', viewValue: 'Averia' },
    { value: 'rotura', viewValue: 'Rotura' },
    { value: 'deterioro', viewValue: 'Deterioro' },
    { value: 'extravio', viewValue: 'Extravio' },
    { value: 'robo', viewValue: 'Robo' },
    { value: 'otro', viewValue: 'Otro' }
  ];
  selectedValue: string;
  incidencias: IncidenciaModel[] = [];
  filteredIncidencias: IncidenciaModel[] = [];
  complexForm: FormGroup;
  incidencias_pdf: IncidenciaModel;
  incList: Array<IncidenciaModel> = [];
  isDownloadable = false;
  list = true;
  nueva = false;
  selectedTab = 0;
  filtroFechaItems = [
    { label: 'Todos', value: 'todo' },
    { label: 'Este mes', value: 'this' },
    { label: 'El mes anterior', value: 'prev' },
    { label: 'Buscar con intervales', value: 'interval' }
  ];
  filtroEstadoItems = [
    { label: 'Pendientes', value: 1 },
    { label: 'En progreso', value: 3 },
    { label: 'Solucionados', value: 2 }
  ];
  selectedEstadoOption = null;
  selectedCategoryOption = null;
  selectedFechaOption = null;
  setDateinput = {
    in: {
      bool: false,
      value: new Date()
    },
    fin: {
      bool: false,
      value: new Date()
    }
  };
  incidenciasCount = 0;
  incidenciasDataCached = [];
  selectedComunity: ComunidadModel;
  users: UserModel[] = [];
  services: ServiceModel[] = [];
  searchValue = '';
  constructor(
    private httpService: HttpService,
    public storageService: StorageService,
    public exporterService: ExporterService,
    public alertService: AlertService,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private modal: NzModalService,
    private store: Store,
    private utilService: UtilsService
  ) {
    this.complexForm = this.fb.group({
      category: [null, Validators.required],
      type: [null, Validators.required],
      description: [null, Validators.required]
    });
  }
  ngOnInit() {
    this.store.dispatch(new GetUsers());
    this.store.dispatch(new GetServices());
    this.initStores();
    this.refresh();
  }

  popImageViewer(url: string) {
    this.store.dispatch(new PopImageViewer(url));
  }
  traspasarIncidencia(incidencia) {
    const inc = { ...incidencia };
    if (this.users && this.users.length > 0) {
      for (const user of this.users) {
        if (user.name === incidencia.username) {
          inc.user = user;
        }
      }
    }
    this.store.dispatch(new SelectTraspasoIncidencia(inc));
  }
  initStores() {
    this.adminUser$.subscribe((adminUser) => {
      if (adminUser) {
        this.adminUser = adminUser;
      }
    });
    this.users$.subscribe((users) => {
      if (users && users.length > 0) {
        this.users = users;
      }
    });
    this.services$.subscribe((services) => {
      if (services && services.length > 0) {
        this.services = services;
      }
    });
    this.incidencias$.subscribe((incidencias) => {
      this.filteredIncidencias = Object.assign([]);
      this.incidencias = Object.assign([]);
      this.selectedEstadoOption = '';
      this.selectedFechaOption = '';
      this.incidencias = incidencias;
      this.mapIncidencias();
    });
    this.selectedComunity$.subscribe((selectedComunity) => {
      if (selectedComunity) {
        this.selectedComunity = selectedComunity;
        this.refresh();
      }
    });
  }
  refresh() {
    this.loadIncidencias();
  }
  selectIncidencia(incidencia) {
    const inc = { ...incidencia };
    if (this.users && this.users.length > 0) {
      for (const user of this.users) {
        if (user.name === incidencia.username) {
          inc.user = user;
        }
      }
    }
    this.store.dispatch(new SelectIncidencia(inc));
  }

  loadIncidencias() {
    this.store.dispatch(new GetIncidencias());
  }
  Export(event) {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: 'Va a exportar la lista de incidencias con el formato excel',
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        try {
          const filename = 'incidencias';
          const clonedList = this.storageService.cloneObject(this.incidenciasDataCached);
          const data: any[] = this.mapIncidenciaForExport(clonedList);
          this.exporterService.exportAsExcelFile(data, filename);
        } catch (error) {}
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }

  mapIncidencias() {
    try {
      if (this.incidencias && this.incidencias.length > 0 && this.users && this.users.length > 0) {
        this.incidencias.forEach((inc) => {
          const incNew = Object.assign({}, inc);
          const user = this.users.find((item) => item.email === inc.user_email);
          if (user) {
            incNew.username = user.name;
          } else {
            incNew.username = inc.username;
          }
          this.filteredIncidencias.push(incNew);
        });
      }
    } catch (error) {
      console.log('map error ->', error);
    }
  }
  mapIncidenciaForExport(data: any[]) {
    data.forEach((item) => {
      item.comunidad = item.code_comunity;
      item.fecha = this.formatDate(item.created_at);
      item.categoria = item.category;
      item.tipo = item.type;
      item.vecino = item.username;
      item.direccion = item.adress;
      item.descripcion = item.description;

      delete item.adress;
      delete item.code_comunity;
      delete item.description;
      delete item.category;
      delete item.type;
      delete item.username;
      delete item.id;
      delete item.image;
      delete item.state;
      delete item.oculto;
      delete item.created_at;
      delete item.create;
    });
    return data;
  }

  showErrorSB() {
    this.snackBar.open('Debe proporcionar las dos fechas', 'ok', {
      duration: 3000
    });
  }

  showErrorSB2() {
    this.snackBar.open('No hay datos para generar el PDF', 'ok', {
      duration: 3000
    });
  }

  generatePDF() {
    if (this.fechaFin === undefined || this.fechaIn === undefined) {
      this.showErrorSB();
    } else {
      this.loading = true;
      this.httpService.getIncidenciasBetweenDates(this.selectedComunity.code, this.fechaIn, this.fechaFin).subscribe((response) => {
        const result = response['result'];
        if (!result) {
        } else {
          this.loading = false;
          this.incidencias_pdf = result;
          this.nb_incidencias = Object.keys(this.incidencias_pdf).length;
          const size = Object.keys(this.incidencias_pdf).length;
          if (size > 0) {
            this.isDownloadable = true;
            this.downloadPDF();
          }
        }
      });
    }
  }

  downloadPDF() {
    const size = Object.keys(this.incidencias_pdf).length;
    for (let i = 0; i < size; i++) {
      this.incList.push(this.incidencias_pdf[i]);
    }

    const incidencias = this.incList;
    const rows: Array<any> = [];
    let index = 1;
    for (const item of incidencias) {
      const row = [index, this.formatSimpleDate(item['created_at']), item['type'], item['category'], item['description']];
      rows.push(row);
      index++;
    }
    const today = this.formatSimpleDate(this.date);
    const inicio = this.formatSimpleDate(this.fechaIn);
    const fin = this.formatSimpleDate(this.fechaFin);

    const header =
      '\t \t \t \t \t    ICOMUNITY \n \n' +
      'INCIDENCIAS \n' +
      'Comunidad : ' +
      this.selectedComunity.name +
      '\n' +
      'fecha : ' +
      today +
      ' \n' +
      'fecha inicio : ' +
      inicio +
      '\t' +
      'fecha fin : ' +
      fin;

    const columns = ['n°', 'fecha', 'tipo', 'categoria', 'descripcion'];
    const doc = new jsPDF('p', 'pt');
    // var doc = new jsPDF();

    doc.autoTable(columns, rows, {
      styles: { overflow: 'linebreak' },
      theme: 'striped',
      columnStyles: {
        0: { columnWidth: 20 },
        1: { columnWidth: 80 },
        2: { columnWidth: 80 },
        3: { columnWidth: 80 },
        4: { columnWidth: 250 }
      },
      margin: { top: 150 },
      addPageContent: function(data) {
        doc.text(header, 40, 30);
      }
    });
    const date = this.datePipe.transform(new Date(), 'yyyy_MM_dd');

    doc.save('incidencias_' + date + '.pdf');
    this.incList = null;
    this.isDownloadable = false;
  }

  formatDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
  }

  formatSimpleDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }
  addInEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.fechaIn = `${type}: ${event.value}`;
    this.fechaIn = this.formatDate(this.fechaIn);
  }

  addFinEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.fechaFin = `${type}: ${event.value}`;
    this.fechaFin = this.formatDate(this.fechaFin);
  }

  changeIncidenciaState(index: number, state: number) {
    if (state === 1) {
      const code = this.incidencias[index].code_comunity;
      const id = this.incidencias[index].id;
      this.httpService.requestChangeIncidenciaState(code, id).subscribe((response) => {});
      this.httpService.requestSendIncidenciaPush(code).subscribe((response) => {});
    } else {
      const code = this.incidencias[index].code_comunity;
      const id = this.incidencias[index].id;
      this.httpService.requestChangeIncidenciaState(code, id).subscribe((response) => {});
    }
  }

  addIncidencia(category: string, type: string, description: string) {
    this.httpService.addAdminIncidencia(this.selectedComunity.code, category, type, description).subscribe((response) => {
      this.model.category = '';
      this.model.type = '';
      this.model.description = '';
      this.loadIncidencias();
      this.selectTab(0);
      this.sendIncidenciaPush();
    });
  }

  sendIncidenciaPush() {
    this.httpService.requestSendIncidenciaPush(this.selectedComunity.code).subscribe((response) => {});
  }

  reload() {
    location.reload();
  }

  selectTab(index: number) {
    this.selectedTab = index;
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    const value = event.value;
    const result = [];
    if (type === 'in') {
      this.setDateinput.in.bool = true;
      this.setDateinput.in.value = value;
    } else if (type === 'fin') {
      this.setDateinput.fin.bool = true;
      this.setDateinput.fin.value = value;
    }
    if (this.setDateinput.in.bool && this.setDateinput.fin.bool) {
      this.incidencias.forEach((item) => {
        const fechatime = new Date(item.created_at).getTime();
        const intime = this.setDateinput.in.value.getTime();
        const fintime = this.setDateinput.fin.value.getTime();
        if (intime < fechatime && fechatime < fintime) {
          result.push(item);
        }
      });
      this.filteredIncidencias = result;
    }
  }

  clearDatePickerInput() {
    this.setDateinput = {
      in: {
        bool: false,
        value: new Date()
      },
      fin: {
        bool: false,
        value: new Date()
      }
    };
  }

  applyFilters() {
    let result = this.incidencias;
    // ESTADO
    if (this.selectedEstadoOption) {
      result = result.filter((item) => item.state === this.selectedEstadoOption);
    }
    // CATEGORY
    if (this.selectedCategoryOption) {
      result = result.filter((item) => item.category === this.selectedCategoryOption);
    }

    // DATE
    if (this.selectedFechaOption) {
      let month = new Date().getMonth();
      const year = new Date().getFullYear();
      switch (this.selectedFechaOption) {
        case 'this':
          // tslint:disable-next-line: max-line-length
          result = result.filter((item) => new Date(item.created_at).getMonth() === month && new Date(item.created_at).getFullYear() === year);
          this.clearDatePickerInput();
          break;
        case 'prev':
          month = new Date().getMonth() - 1;
          // tslint:disable-next-line: max-line-length
          result = result.filter((item) => new Date(item.created_at).getMonth() === month && new Date(item.created_at).getFullYear() === year);
          this.clearDatePickerInput();
          break;
        case 'interval':
          result = result;
          break;
        case 'todo':
          result = result;
          this.clearDatePickerInput();
          break;
      }
    }
    this.filteredIncidencias = result;
  }

  onEstadoChange(event) {
    this.selectedEstadoOption = event.value;
    this.applyFilters();
  }

  onServiceChange(event) {
    this.selectedCategoryOption = event.value;
    this.applyFilters();
  }

  onDateFilterChange(event) {
    this.selectedFechaOption = event.value;
    this.applyFilters();
  }

  clearFilters() {
    this.selectedFechaOption = null;
    this.selectedEstadoOption = null;
    this.selectedCategoryOption = null;
    this.filteredIncidencias = this.incidencias;
  }
}

interface Model {
  category: string;
  type: string;
  description: string;
}
interface Service {
  type: string;
  name: string;
}
