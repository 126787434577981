import { PAGES_TYPE_ENUM } from '../../common/enums/pages-type.enum';
import { SideNavMenuItem } from '../../model/sidenav-menu-item.model';

export const planBasicMessage = 'Las funcionalidades están limitadas en esta comunidad, póngase en contacto con nuestro equipo de Soporte para más informaciones.';
export const DEFAULT_PLAN_INFOS_ICONS = {
  logo: 'assets/new-images/logo-centered.png',
  OK: 'assets/img/plan-ok.png',
  BASIC: 'assets/img/plan-basic.png',
  EXPIRED: 'assets/img/plan-expired.png'
};
export const LOGOUT_ICON = 'assets/new-images/logout-w.png';

export const SIDENAV_MENU_ITEMS: SideNavMenuItem[] = [
  {
    label: 'Inicio',
    name: PAGES_TYPE_ENUM.HOME,
    icon: 'assets/new-images/menu-home-w.png',
    route: '/home',
    active: true,
    hidden: false
  },
  {
    label: 'Noticias',
    name: PAGES_TYPE_ENUM.NOTICIAS,
    icon: 'assets/new-images/menu-noticias-w.png',
    route: '/noticias',
    active: false,
    hidden: true
  },
  {
    label: 'Incidencias',
    name: PAGES_TYPE_ENUM.INCIDENCIAS,
    icon: 'assets/new-images/menu-incidencias-w.png',
    route: '/incidencias',
    active: false,
    hidden: true
  },
  {
    label: 'Gestión de propietarios',
    name: PAGES_TYPE_ENUM.GESTION,
    icon: 'assets/new-images/menu-gestion-w.png',
    route: '/gestion',
    active: false,
    hidden: true
  },
  {
    label: 'Documentos',
    name: PAGES_TYPE_ENUM.DOCUMENTOS,
    icon: 'assets/new-images/menu-doc-w.png',
    route: '/documents',
    active: false,
    hidden: true
  },
  {
    label: 'Reservas',
    name: PAGES_TYPE_ENUM.RESERVAS,
    icon: 'assets/new-images/menu-reservas-w.png',
    route: '/booking',
    active: false,
    hidden: true
  },
  {
    label: 'Facturas',
    name: PAGES_TYPE_ENUM.FACTURAS,
    icon: 'assets/new-images/menu-billing-w.png',
    route: '/billing',
    active: false,
    hidden: true
  },
  {
    label: 'Ajustes',
    name: PAGES_TYPE_ENUM.AJUSTES,
    icon: 'assets/new-images/menu-settings-w.png',
    route: '/settings',
    active: false,
    hidden: true
  },
  {
    label: 'Thor',
    name: PAGES_TYPE_ENUM.THOR,
    icon: 'assets/new-images/menu-thor-w.png',
    route: '/thor',
    active: false,
    hidden: true
  }
];
