import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginLayoutComponent } from './layouts/login-layout.component';
import { HomeLayoutComponent } from './layouts/home-layout.component';
import { AuthGuard } from './common/guards/auth.guard';
import { HomeComponent } from './views/home/home.component';
import { LoginComponent } from './views/login/login.component';
import { AdminAuthGuard } from './common/guards/admin-guard';
import { AdminComponent } from './views/admin/admin.component';
import { BookingComponent } from './views/booking/booking.component';
import { DocumentsComponent } from './views/documents/documents.component';
import { GestionComponent } from './views/gestion/gestion.component';
import { IncidenciasComponent } from './views/incidencias/incidencias.component';
import { NoticiasComponent } from './views/noticias/noticias.component';
import { SettingsComponent } from './views/settings/settings.component';
import { FacturasComponent } from './views/facturas/facturas.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
      },
      {
        path: 'home',
        component: HomeComponent
      },
      { path: 'noticias', component: NoticiasComponent },
      { path: 'incidencias', component: IncidenciasComponent },
      { path: 'gestion', component: GestionComponent },
      { path: 'booking', component: BookingComponent },
      { path: 'documents', component: DocumentsComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'billing', component: FacturasComponent },
      { path: 'thor', component: AdminComponent, canActivate: [AdminAuthGuard] }
    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
