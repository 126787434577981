import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DialogLogoutComponent } from '../dialog-logout/dialog-logout.component';
import { ComunidadModel } from '../../model/comunidad.model';
import { SelectComunity } from '../../views/home/store/home.actions';
import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { AdminUserModel } from '../../model/admin-user.model';
import { ModalService } from '../../services/modal/modal.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Select((state) => state.core.selectedComunity) selectedComunity$: Observable<any>;
  @Select((state) => state.core.comunities) comunities$: Observable<any>;
  @Select((state) => state.core.adminUser) adminUser$: Observable<any>;
  @Select((state) => state.core.christmasAnimationActive) christmasActive$: Observable<any>;

  @Input() title = '';
  fileNameDialogRef: MatDialogRef<DialogLogoutComponent>;
  selectedComunity: ComunidadModel;
  selectedComunityName = '';
  adminUser: AdminUserModel;
  logo = 'assets/img/logo.png';
  planOK = 'assets/img/plan-ok.png';
  planBASIC = 'assets/img/plan-basic.png';
  planEXPIRED = 'assets/img/plan-expired.png';
  menuIconHome = 'assets/new-images/menu-home-w.png';
  menuIconNoticias = 'assets/new-images/menu-noticias-w.png';
  menuIconIncidencias = 'assets/new-images/menu-incidencias-w.png';
  menuIconGestion = 'assets/new-images/menu-gestion-w.png';
  menuIconDocumentos = 'assets/new-images/menu-doc-w.png';
  menuIconReservas = 'assets/new-images/menu-reservas-w.png';
  menuIconSettings = 'assets/new-images/menu-settings-w.png';
  menuIconThor = 'assets/new-images/menu-thor-w.png';
  settingsIcon = 'assets/new-images/settings-w.png';
  christmasActive = true;
  constructor(public dialog: MatDialog, private store: Store, public modalService: ModalService) {}

  onSelected(comunity: ComunidadModel) {
    this.store.dispatch(new SelectComunity(comunity));
    this.selectedComunityName = comunity.name;
  }

  ngOnInit() {
    this.selectedComunity$.subscribe((selectedComunity) => {
      if (selectedComunity) {
        this.selectedComunity = selectedComunity;
        this.selectedComunityName = selectedComunity.name;
      }
    });
    this.adminUser$.subscribe((adminUser) => {
      if (adminUser) {
        this.adminUser = adminUser;
      }
    });
  }

  showSettings() {
    this.modalService.toggleSettingsModal(true);
  }

  logout() {
    this.fileNameDialogRef = this.dialog.open(DialogLogoutComponent, {
      hasBackdrop: true,
      minHeight: 150,
      minWidth: 300
    });
  }
}
